import * as React from "react";

import { FONT_WEIGHT } from "constants/styles";

import styled from "styled-components";
import { Link as GatsbyLink } from "gatsby";

const NotFoundPage = () => {
  const StyledLink = styled(GatsbyLink)`
    text-decoration: none;
    color: #5cd89f;
    font-weight: ${FONT_WEIGHT.bold};
  `;

  const ErrorLayoutStyle = {
    textAlign: "center",
    margin: "4rem 2rem",
    fontSize: "1.3rem",
  };

  return (
    <div style={ErrorLayoutStyle}>
      <h1 style={{ marginBottom: "2rem" }}>Page not found</h1>
      <p style={{ marginBottom: "2rem" }}>Sorry, that page does not exist.</p>
      <p>
        You can check our <StyledLink to="/">User Guide</StyledLink>.
      </p>
    </div>
  );
};

export default NotFoundPage;
